import { Experiment, Variants } from "@amplitude/experiment-js-client";
import { useQuery } from "@tanstack/react-query";
import config from "common/config";

const experiment = config.AMPLITUDE_API_KEY
  ? Experiment.initializeWithAmplitudeAnalytics(config.AMPLITUDE_API_KEY)
  : undefined;

type AmplitudeFeatureFlagKeys = "location_machine_sleep_time" | "notification";

async function getAmplitudeFeatureFlags(): Promise<Variants> {
  if (!experiment) {
    throw new Error(
      "Amplitude API key is not defined in environment variables."
    );
  }
  await experiment.fetch();
  return experiment.all();
}

export function useAmplitudeFeatureFlag(key: AmplitudeFeatureFlagKeys) {
  const response = useQuery({
    queryKey: ["amplitude-feature-flags"],
    queryFn: getAmplitudeFeatureFlags,
    enabled: experiment !== undefined,
    throwOnError: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return {
    enable: response.data ? response?.data[key]?.key === "on": false,
    ...response
  };
}
